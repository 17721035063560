import * as React from 'react'

const SVG = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.22 6.0001C41.32 7.9201 40.66 10.3601 38.74 11.4601L18.5 23.1601V40.0001C19.0304 40.0001 19.5391 40.2108 19.9142 40.5859C20.2893 40.961 20.5 41.4697 20.5 42.0001V44.0001H4.5V42.0001C4.5 41.4697 4.71071 40.961 5.08579 40.5859C5.46086 40.2108 5.96957 40.0001 6.5 40.0001V22.0001C6.5 20.4088 7.13214 18.8827 8.25736 17.7575C9.38258 16.6322 10.9087 16.0001 12.5 16.0001C13.12 16.0001 13.74 16.1201 14.34 16.3201L34.74 4.5401C36.68 3.4201 39.12 4.0801 40.22 6.0001ZM15.5 40.0001V27.2001C13.64 28.2801 11.36 28.2801 9.5 27.2001V40.0001H15.5ZM12.5 25.0001C13.2957 25.0001 14.0587 24.684 14.6213 24.1214C15.1839 23.5588 15.5 22.7958 15.5 22.0001C15.5 21.2045 15.1839 20.4414 14.6213 19.8788C14.0587 19.3162 13.2957 19.0001 12.5 19.0001C11.7044 19.0001 10.9413 19.3162 10.3787 19.8788C9.81607 20.4414 9.5 21.2045 9.5 22.0001C9.5 22.7958 9.81607 23.5588 10.3787 24.1214C10.9413 24.684 11.7044 25.0001 12.5 25.0001ZM21.3 19.2401L23.9 17.7401L19.3 15.7401L16.7 17.2401L21.3 19.2401ZM28.22 15.2401L30.82 13.7401L26.22 11.7401L23.62 13.2401L28.22 15.2401ZM35.16 11.2401L37.74 9.7401L33.16 7.7401L30.56 9.2401L35.16 11.2401ZM32.5 40.0001V30.0001H26.5L34.5 22.3201L42.5 30.0001H36.5V40.0001H32.5Z"
      fill="white"
    />
  </svg>
)

export default SVG
