import { useRouter } from 'next/router'
import cn from 'classnames'
import { AreaInfoResponseData } from '@/store/slices/stagesSlice/interfaces'
import { Icon } from '@/components'
import { IconName } from '@/assets/icons/interfaces'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './AreaPageMain.module.scss'

export const AreaPageMain = ({ area }: { area: AreaInfoResponseData | null }) => {
  const isButtonWithoutLineBreak = !area?.show_subscriptions || !area?.show_events
  const router = useRouter()
  const findCarNumberPageHref = getLinkHref({ type: 'find-car-number', data: { id: area?.id } })
  const subscriptionsPageHref = getLinkHref({ type: 'subscriptions', data: { areaId: area?.id } })
  const eventPageHref = getLinkHref({ type: 'events', data: { areaId: area?.id } })

  return (
    <main className={styles.main}>
      <div className={styles.main__content}>
        <h1 className={styles.main__areaName}>{area?.name}</h1>
        <p className={styles.main__address}>
          <Icon name="location-icon" />
          {area?.address}
        </p>

        <nav className={styles.menu}>
          {area?.show_rates && (
            <MenuButton
              text="Оплатить парковку"
              onClick={() => router.push(findCarNumberPageHref)}
              icon="barrier-up-icon"
              isAccent
              withoutLineBreak
            />
          )}
          <div className={styles.menu__buttons}>
            {area?.show_subscriptions && (
              <MenuButton
                text="Купить абонемент"
                onClick={() => router.push(subscriptionsPageHref)}
                icon="subscription-icon"
                withoutLineBreak={isButtonWithoutLineBreak}
              />
            )}
            {area?.show_events && (
              <MenuButton
                text="Оплатить мероприятие"
                onClick={() => router.push(eventPageHref)}
                icon="event-icon"
                withoutLineBreak={isButtonWithoutLineBreak}
              />
            )}
          </div>
        </nav>
      </div>
    </main>
  )
}

const MenuButton = ({
  text,
  icon,
  isAccent,
  withoutLineBreak,
  onClick,
}: {
  text: string
  icon: IconName
  isAccent?: boolean
  withoutLineBreak: boolean
  onClick: () => void
}) => {
  return (
    <button
      className={cn(styles.menu__button, {
        [styles.menu__button_accent]: isAccent,
        [styles.menu__button_without_line_break]: withoutLineBreak,
      })}
      onClick={onClick}
    >
      <div>
        <Icon name={icon} />
        <span>{text}</span>
      </div>
    </button>
  )
}
