import cn from 'classnames'
import {
  getSubscriptionInfoById,
  startSubscriptionPayment,
  getPaymentState,
  getAreaInfoById,
  changeUserEmail,
  getUserInformation,
  getPaymentInfoById,
  getFinesInfoById,
} from '@/store/slices/stagesSlice/stagesSlice'
import { Container, Button, Card, Title, Text, Paragraph } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import unicode from '@/helpers/unicodeChars'
import formatNumberToString from '@/helpers/formatNumberToString'
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles'
import { useRouter } from 'next/router'
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import CarNumberLabel from '../PaymentPage/CarNumberLabel'
import getSubscriptionInterval from '@/helpers/getSubscriptionInterval'
import styles from './SubscriptionPaymentPage.module.scss'
import EmailInput from '@/components/ui/EmailInput'
import { deleteCookie } from 'cookies-next'
import { supportPhoneLink } from '@/constants';

export default function SubscriptionPaymentPage({
  subscriptionId,
  number,
  stageId,
}: {
  subscriptionId: string
  number: string
  stageId: string | null
}) {
  const fieldRef = useRef(null)
  const reduxState = useSelector<RootState, RootState>(state => state)
  const dispatch = useDispatch()
  const router = useRouter()
  useAreaStyles(router.query.areaId as string)
  const { data } = getSubscriptionInfoById(reduxState, subscriptionId)
  const { data: sessionPaymentInfo } = getPaymentInfoById(reduxState, stageId)
  const { data: finesInfo } = getFinesInfoById(reduxState, stageId)
  const { loading } = getPaymentState(reduxState)
  const [error, setError] = useState('')

  const [emailValue, setEmailValue] = useState('')
  const [emailInputError, setEmailInputError] = useState('')
  const { data: areaInfo } = getAreaInfoById(reduxState, data?.area.id || null)

  const totalPrice = data?.subscription.amount || '0'
  const areaInn = data?.area?.inn ?? ''
  const areaLegalName = data?.area?.legal_name ?? ''

  const price = `${formatNumberToString(
    +totalPrice +
      (sessionPaymentInfo ? +sessionPaymentInfo.amount : 0) +
      (finesInfo?.totalAmount ? finesInfo.totalAmount : 0),
  )}${unicode.no_break_space}${unicode.ruble}`

  const subscriptionInterval = data
    ? getSubscriptionInterval({
        type_work: data.subscription.type_work,
        interval_type: data.subscription.interval_type,
        interval: data.subscription.interval,
        fixed_period_from: data.subscription.fixed_period_from,
        fixed_period_to: data.subscription.fixed_period_to,
      })
    : ''

  const user = getUserInformation(reduxState)
  const handleStartPayment = useCallback(async () => {
    if (emailValue && !emailValue.match(/.+[@].+/gm)) {
      setEmailInputError('Укажите корректный email')
      return
    }
    const email = emailValue.trim()
    await dispatch(changeUserEmail({ email }))
    await dispatch(
      startSubscriptionPayment({
        user,
        subscriptionId,
        number,
        onSuccess: ({ data }) => router.push(data.url),
        onError: (message: string) => setError(message),
      }),
    )
    deleteCookie('link_processing_personal_data')
    deleteCookie('link_parking_rules')
  }, [subscriptionId, number, emailValue])

  useEffect(() => {
    if (emailInputError) {
      const field = (fieldRef.current as unknown) as HTMLInputElement
      field?.focus()
    }
  }, [emailInputError])

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleStartPayment()
    }
  }

  if (!data)
    return (
      <Container>
        <Card color="decor">
          <div className={styles.notFound}>
            <div className={styles.notFound_Image}>
              <img src="/images/subscription-not-found.svg" alt="" />
            </div>
            <span>Нет информации по данному абонементу</span>
          </div>
        </Card>
      </Container>
    )

  const isShowSessionDetails = sessionPaymentInfo && +sessionPaymentInfo.amount > 0
  const isShowFinesDetails = finesInfo && finesInfo.totalAmount > 0
  const isShowPaymentDetails = isShowSessionDetails || isShowFinesDetails

  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <Container>
          <header className={styles.header}>
            <Title className={styles.price}>{price}</Title>
            <Paragraph className={styles.interval}>{subscriptionInterval}</Paragraph>
            <CarNumberLabel number={number} />
          </header>
          {isShowPaymentDetails && (
            <>
              <div className={styles.paymentDetails}>
                <Title level={4} className={styles.paymentDetails_title}>
                  К оплате
                </Title>
                <div className={styles.paymentDetails_row}>
                  <Text level={2}>Абонемент</Text>
                  <Text level={2} className={styles.paymentDetails_price}>
                    {formatNumberToString(+totalPrice)}
                    {unicode.no_break_space}
                    {unicode.ruble}
                  </Text>
                </div>
                {isShowSessionDetails && (
                  <div className={styles.paymentDetails_row}>
                    <Text level={2}>Стоимость сессии</Text>
                    <Text level={2} className={styles.paymentDetails_price}>
                      {formatNumberToString(+sessionPaymentInfo.amount)}
                      {unicode.no_break_space}
                      {unicode.ruble}
                    </Text>
                  </div>
                )}
                {isShowFinesDetails && (
                  <div className={styles.paymentDetails_row}>
                    <Text level={2}>Штраф</Text>
                    <Text level={2} className={styles.paymentDetails_price}>
                      {formatNumberToString(finesInfo.totalAmount)}
                      {unicode.no_break_space}
                      {unicode.ruble}
                    </Text>
                  </div>
                )}
              </div>
              <div className={styles.totalAmount}>
                <p>Итого: </p>
                <p>{price}</p>
              </div>
            </>
          )}
        </Container>

        <Container className={styles.email_container}>
          <Title className={styles.content__title} level={4} size={24}>
            Укажите ваш email
          </Title>

          <Text level={2} className={styles.content__paragraph}>
            Ваш email необходим, чтобы мы могли прислать чек об успешной оплате.
          </Text>

          <label className={cn(styles.input, { [styles['input--active']]: !!emailValue })}>
            <EmailInput
              onChange={setEmailValue}
              onKeyPress={handleKeyPress}
              value={emailValue}
              error={emailInputError || error}
            />
          </label>
        </Container>

        <Container>
          <div className={styles.requisites}>
            <span>{areaLegalName}</span>
            <span>ИНН {areaInn}</span>
          </div>
        </Container>
      </div>

      <Container className={styles.buttons}>
        <Button
          disabled={emailValue?.length < 6}
          withLoader
          onClick={handleStartPayment}
          className={styles.button}
          loading={loading}
          background={areaInfo?.customising.button_back_color}
        >
          К оплате
        </Button>
        <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
          Обратиться в поддержку
        </Button>
      </Container>
    </section>
  )
}
