import { getSupportPhoneLink } from '@/helpers/getSupportPhoneLink';

export const NUMBER_CAR = 'number_car'

export enum AreasSlug {
  KashirskayaPlaza = 'kashirskaya_plaza',
  KapitoliyBelyaevo = 'kapitoliy_belyaevo',
  ParkovkaZil = 'parkovkazil',
}

export const supportPhone = '8 800 200 33 20'
export const supportPhoneLink = getSupportPhoneLink(supportPhone)