import * as React from 'react'

const SVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42 9C42 8.20435 41.6839 7.44129 41.1213 6.87868C40.5587 6.31607 39.7957 6 39 6H33V3H30V6H18V3H15V6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9V39C6 39.7957 6.31607 40.5587 6.87868 41.1213C7.44129 41.6839 8.20435 42 9 42H15V39H9V9H15V12H18V9H30V12H33V9H39V18H42V9Z"
      fill="white"
    />
    <path
      d="M31.5 22.5L35.3235 29.907L43.5 31.0935L37.5 36.8595L39 45L31.5 41.157L24 45L25.5 36.8595L19.5 31.0935L27.9 29.907L31.5 22.5Z"
      fill="white"
    />
  </svg>
)

export default SVG
