import Head from 'next/head'

export default function NextHead() {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      
      <meta name="format-detection" content="telephone=no" />

      {/* TODO: add favicons */}
      {/* <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" /> */}
      {/* <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> */}
      {/* <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" /> */}
      {/* <link rel="manifest" href="/site.webmanifest" /> */}
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#FFFFFF" />
      <link rel="shortcut icon" href="images/favicon.svg" />
      {/* <meta name="msapplication-config" content="/browserconfig.xml" /> */}
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="stylesheet" type="text/css" charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <script
        id="yandex-metrika"
        dangerouslySetInnerHTML={{
          __html: `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; 
   m[i].l=1*new Date(); 
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} 
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) 
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); 
  
   ym(99998859, "init", { 
        clickmap:true, 
        trackLinks:true, 
        accurateTrackBounce:true, 
        webvisor:true 
   });
          `
        }}
      /> 
    </Head>
  )
}
