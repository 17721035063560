import { ReactNode, useMemo, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import getConfig from 'next/config'
import classNames from 'classnames'
import axios from 'axios'
import { Icon } from '@/components'
import { PartnerPromotion } from '@/store/slices/stagesSlice/interfaces'
import { sliderSettings } from '@/components/PartnerPromotions/PartnerPromotions.constants'
import { sortPartnerPromotions } from '@/components/PartnerPromotions/PartnerPromotions.helpers'
import PromoModal from '@/components/PromoModal'
import styles from './PartnerPromotions.module.scss'
import { yandexMetrika } from '@/helpers/yandexMetrika'

const { publicRuntimeConfig } = getConfig()

export default function PartnerPromotions({
  partnerPromotions,
}: {
  partnerPromotions: PartnerPromotion[]
}) {
  const [isPromoModalOpen, setIsPromoModalOpen] = useState<boolean>(false)
  const [selectedPartnerPromotion, setSelectedPartnerPromotion] = useState<PartnerPromotion | null>(
    null,
  )

  const sortedPartnerPromotions = sortPartnerPromotions(partnerPromotions)

  const memoSliderSettings = useMemo((): Settings => {
    return {
      ...sliderSettings,
      prevArrow:
        partnerPromotions?.length < 2 ? (
          <></>
        ) : (
          <button className={styles.partnerPromotion__sliderButton}>
            <Icon name="arrow" />
          </button>
        ),
      customPaging(): ReactNode {
        return <button className={styles.sliderDot} />
      },
      nextArrow:
        partnerPromotions?.length < 2 ? (
          <></>
        ) : (
          <button className={styles.partnerPromotion__sliderButton}>
            <Icon name="arrow" />
          </button>
        ),
      className: classNames(styles.slider, {
        [styles.isOne]: partnerPromotions?.length === 1,
      }),
      slidesToShow: partnerPromotions?.length < 2 ? 1 : sliderSettings.slidesToShow,
      dots: partnerPromotions?.length < 2 ? false : sliderSettings.dots,
    }
  }, [partnerPromotions])

  const handlePartnerPromotionClick = async (partnerPromotion: string) => {
    yandexMetrika.reachGoalButtonClickMoreDetails()
    await axios.post(
      `${publicRuntimeConfig.browserApiUrl}/partner-promotions/${partnerPromotion}/metrics`,
    )
  }

  const handleAdvertisingButtonClick = (partnerPromotion: PartnerPromotion) => {
    setSelectedPartnerPromotion(partnerPromotion)
    setIsPromoModalOpen(true)
  }

  return (
    <>
      <div className={styles.container}>
        <Slider {...memoSliderSettings}>
          {sortedPartnerPromotions?.map(partnerPromotion => (
            <article
              key={partnerPromotion.id}
              className={classNames(styles.partnerPromotion, {
                [styles.isOne]: partnerPromotions?.length === 1,
              })}
            >
              {partnerPromotion.partner_name && (
                <button
                  className={styles.advertisingButton}
                  onClick={() => handleAdvertisingButtonClick(partnerPromotion)}
                >
                  Реклама
                </button>
              )}
              <div
                className={styles.partnerPromotion__content}
                style={{
                  backgroundImage: `url(${partnerPromotion.banner_url})`,
                }}
              >
                <p className={styles.partnerPromotion__content_name}>{partnerPromotion.name}</p>
                <a
                  href={partnerPromotion.url}
                  className={styles.partnerPromotion__content_link}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => handlePartnerPromotionClick(partnerPromotion.slug)}
                >
                  Подробнее <Icon name="arrow" />
                </a>
              </div>
            </article>
          ))}
        </Slider>
      </div>
      {isPromoModalOpen && selectedPartnerPromotion && (
        <PromoModal setIsOpen={setIsPromoModalOpen} partnerPromotion={selectedPartnerPromotion} />
      )}
    </>
  )
}
