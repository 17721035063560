import { Container, Card, Button } from '@/components'
import { getAreaInfoById } from '@/store/slices/stagesSlice/stagesSlice'
import styles from './NotFoundPage.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useRouter } from 'next/router'
import { supportPhoneLink } from '@/constants';

export default function NotFoundPage({ areaId }: { areaId: string }) {
  const router = useRouter()
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)

  const goBack = () => {
    router.back()
  }

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <Card className={styles.card} color="decor">
            <div className={styles.card_Image}>
              <img src="/images/car-not-found.svg" alt="" />
            </div>
            <div className={styles.card_Text}>У вас нет активной сессии</div>
          </Card>
        </div>
        <footer className={styles.section__footer}>
          <Button
            type="submit"
            withLoader
            className={styles.button}
            onClick={goBack}
            background={areaInfo?.customising.button_back_color}
          >
            Проверить гос. номер
          </Button>
          <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
            Обратиться в поддержку
          </Button>
        </footer>
      </Container>
    </section>
  )
}
