const reachGoal = (counterId: number, target: string) => {
    if (typeof window !== "undefined" && "ym" in window) {
      (<any>window).ym(counterId, 'reachGoal', target);
    }
}

const reachGoalButtonClickGooglePlay = () => {
  reachGoal(99998859, 'button-click-google-play');
}

const reachGoalButtonClickGooglePlayNew = () => {
  reachGoal(99998859, 'button-click-google-play-new');
}

const reachGoalButtonClickAppStore = () => {
  reachGoal(99998859, 'button-click-appstore');
}

const reachGoalButtonClickAppStoreNew = () => {
  reachGoal(99998859, 'button-click-appstore-new');
}

const reachGoalButtonClickMoreDetails = () => {
  reachGoal(99998859, 'button-click-more-details');
}

export const yandexMetrika = {
    reachGoalButtonClickGooglePlay,
    reachGoalButtonClickGooglePlayNew,
    reachGoalButtonClickAppStore,
    reachGoalButtonClickAppStoreNew,
    reachGoalButtonClickMoreDetails,
}
