import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import { AppThunk, RootState } from '@/store'
import {
  AreaEventsError,
  AreaEventsResponseData,
  AreaEventsSuccess,
  AreaInfoError,
  AreaInfoResponseData,
  AreaInfoSuccess,
  AreaSubscriptionsError,
  AreaSubscriptionsResponseData,
  AreaSubscriptionsSuccess,
  CarsResponseData,
  DiscountErrorResponse,
  DiscountResponseData,
  DiscountSuccessResponse,
  Error,
  EventInfoError,
  EventInfoResponseData,
  EventInfoSuccess,
  FindCarFields,
  isAreaEventsSuccess,
  isAreaInfoSuccess,
  isAreaSubscriptionsSuccess,
  isEventInfoSuccess,
  isPaymentCheckResultSuccess,
  isPaymentInfoSuccess,
  isPurchasedSubscriptionInfoSuccess,
  isResponseStrictSearchStageSuccess,
  isResponseSuccess,
  isSavePurchasedSubscriptionSuccess,
  isStartPaymentSuccess,
  isSubscriptionInfoSuccess,
  PaymentCheckError,
  PaymentCheckResponseData,
  PaymentCheckSuccess,
  PaymentInfoError,
  PaymentInfoResponseData,
  PaymentInfoSuccess,
  PaymentStatus,
  PostError,
  PurchasedSubscriptionInfoError,
  PurchasedSubscriptionInfoResponseData,
  PurchasedSubscriptionInfoSuccess,
  ResponseError,
  ResponseSearchStageByStrictSearchSuccess,
  ResponseSuccess,
  SavePurchasedSubscriptionError,
  SavePurchasedSubscriptionSuccess,
  SliceState,
  StartPaymentError,
  StartPaymentResponseData,
  StartPaymentSuccess,
  SubscriptionInfoError,
  SubscriptionInfoResponseData,
  SubscriptionInfoSuccess,
  UserType,
  FinesInfoResponseData,
  FinesInfoSuccess,
  FinesInfoError,
  isFinesInfoSuccess,
  ApplyReceiptDiscountSuccess,
  ApplyReceiptDiscountError,
  isApplyReceiptDiscountSuccess,
  PaymentReceiptResponseData,
  PaymentReceiptSuccess,
  PaymentReceiptError,
  isPaymentReceiptSuccess,
  PartnerPromotion,
  isPartnerPromotionsSuccess,
  PartnerPromotionsError,
  PartnerPromotionsSuccess,
  MobileApps,
  isMobileAppsSuccess,
  MobileAppsError,
  MobileAppsSuccess,
} from './interfaces'
import { getCookie } from 'cookies-next'
import { NUMBER_CAR } from '@/constants'

const initialState: SliceState = {
  areaId: null,
  selectedId: null,
  userEmail: null,
  cached: {},
  loading: false,
  error: null,
  discountInfo: {
    loading: false,
    errors: null,
    data: null,
  },
  discountAreaInfo: {
    loading: false,
    errors: null,
    data: null,
  },
  areaInfo: {
    cached: {},
    loading: false,
    error: null,
  },
  areaSubscriptions: {
    cached: {},
    loading: false,
    error: null,
  },
  areaEvents: {
    cached: {},
    loading: false,
    error: null,
  },
  paymentInfo: {
    cached: {},
    loading: false,
    error: null,
  },
  subscriptionInfo: {
    cached: {},
    loading: false,
    error: null,
  },
  eventInfo: {
    cached: {},
    loading: false,
    error: null,
  },
  payment: {
    loading: false,
    data: {
      url: null,
      token: null,
      status: 'pending',
      paidUntil: null,
      paidUntilEvent: null,
      platform: null,
      area: null,
      type: null,
      stageId: null,
    },
  },
  applyReceiptDiscount: {
    loading: false,
    error: null,
  },
  user: null,
  purchasedSubscriptionInfo: {
    data: null,
    loading: false,
    error: null,
  },
  savePurchasedSubscription: {
    loading: false,
    error: null,
  },
  finesInfo: {
    cached: {},
    loading: false,
    error: null,
  },
  paymentReceipt: {
    cached: {},
    loading: false,
    error: null,
  },
  partnerPromotions: {
    data: null,
    loading: false,
    error: null,
  },
  mobileApps: {
    data: null,
    error: null,
  },
}

const stages = createSlice({
  name: 'stages',
  initialState: initialState,
  reducers: {
    changeSelectedId(state, { payload }: PayloadAction<{ id: number | null }>) {
      state.selectedId = payload.id
    },
    changeUserEmail(state, { payload }: PayloadAction<{ email: string | null }>) {
      state.userEmail = payload.email
    },
    requestAreaInfo(state) {
      state.areaInfo.loading = true
    },
    successAreaInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: AreaInfoResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.areaInfo
      if (data) {
        cached[key] = { data, error: null }
      }
      state.areaInfo.loading = false
    },
    failureAreaInfo(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.areaInfo
      cached[key] = { data: null, error: err }

      state.areaInfo.loading = false
    },
    requestAreaSubscriptions(state) {
      state.areaSubscriptions.loading = true
    },
    successAreaSubscriptions(
      state,
      { payload }: PayloadAction<{ key: string; data?: AreaSubscriptionsResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.areaSubscriptions
      if (data) {
        cached[key] = { data, error: null }
      }
      state.areaSubscriptions.loading = false
    },
    failureAreaSubscriptions(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.areaSubscriptions
      cached[key] = { data: null, error: err }

      state.areaSubscriptions.loading = false
    },
    requestAreaEvents(state) {
      state.areaEvents.loading = true
    },
    successAreaEvents(
      state,
      { payload }: PayloadAction<{ key: string; data?: AreaEventsResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.areaEvents
      if (data) {
        cached[key] = { data, error: null }
      }
      state.areaEvents.loading = false
    },
    failureAreaEvents(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.areaEvents
      cached[key] = { data: null, error: err }

      state.areaEvents.loading = false
    },
    requestCars(state) {
      state.loading = true
    },
    successCars(state, { payload }: PayloadAction<{ key: string; data?: CarsResponseData }>) {
      const { key, data } = payload
      const { cached } = state
      if (data) {
        cached[key] = { data, error: null }
      }
      state.loading = false
    },
    failureCars(state, { payload }: PayloadAction<{ key: string; err: PostError<FindCarFields> }>) {
      const { key, err } = payload
      const { cached } = state
      cached[key] = { data: null, error: err }

      state.loading = false
    },
    requestPaymentInfo(state) {
      state.paymentInfo.loading = true
    },
    successPaymentInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: PaymentInfoResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.paymentInfo
      if (data) {
        cached[key] = { data, error: null }
      }
      state.paymentInfo.loading = false
    },
    failurePaymentInfo(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.paymentInfo
      cached[key] = { data: null, error: err }

      state.paymentInfo.loading = false
    },
    resetPaymentInfo(state) {
      state.paymentInfo = {
        cached: {},
        loading: false,
        error: null,
      }
    },
    requestStartPayment(state) {
      state.payment.loading = true
    },
    successStartPayment(state, { payload }: PayloadAction<{ data: StartPaymentResponseData }>) {
      state.payment.loading = false
      state.payment.data = { ...state.payment.data, ...payload.data }
    },
    requestStartDiscount(state) {
      state.discountInfo.loading = true
    },
    successDiscount(state, { payload }: PayloadAction<Pick<DiscountSuccessResponse, 'data'>>) {
      state.discountInfo.errors = null
      state.discountInfo.loading = false
      state.discountInfo.data = payload.data
    },
    failureDiscountInfo(state, { payload }: PayloadAction<DiscountErrorResponse | undefined>) {
      const errors = payload?.errors
      state.discountInfo.loading = false
      if (errors) {
        state.discountInfo.errors = errors
      }
    },
    successDiscountAreaInfo(state, { payload }: PayloadAction<{ data: DiscountResponseData }>) {
      state.discountAreaInfo.errors = null
      state.discountAreaInfo.loading = false
      state.discountAreaInfo.data = payload.data
    },
    failureDiscountAreaInfo(state, { payload }: PayloadAction<DiscountErrorResponse | undefined>) {
      const errors = payload?.errors
      state.discountAreaInfo.loading = false
      if (errors) {
        state.discountInfo.errors = errors
      }
    },
    failureStartPayment(state) {
      state.payment.loading = false
    },
    requestCheckPayment(state) {
      state.payment.loading = true
      state.payment.data = { ...state.payment.data, status: 'pending' }
    },
    successCheckPayment(state, { payload }: PayloadAction<PaymentCheckResponseData>) {
      state.payment.loading = false
      state.payment.data = {
        ...state.payment.data,
        status: 'success',
        area: payload?.area ?? null,
        paidUntil: payload?.reason?.paid_until ?? null,
        paidUntilEvent:
          payload?.reason?.type === 'ClientEvent'
            ? { start_at: payload?.reason?.start_at, end_at: payload?.reason?.end_at }
            : null,
        type: payload?.reason?.type ?? null,
        stageId: payload?.reason?.id ?? null,
        platform: payload?.platform ?? null,
      }
    },
    failureCheckPayment(state) {
      state.payment.loading = false
      state.payment.data = { ...state.payment.data, status: 'error' }
    },
    requestSubscriptionInfo(state) {
      state.subscriptionInfo.loading = true
    },
    successSubscriptionInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: SubscriptionInfoResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.subscriptionInfo
      if (data) {
        cached[key] = { data, error: null }
      }
      state.subscriptionInfo.loading = false
    },
    failureSubscriptionInfo(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.subscriptionInfo
      cached[key] = { data: null, error: err }

      state.subscriptionInfo.loading = false
    },
    requestApplyReceiptDiscount(state) {
      state.applyReceiptDiscount.loading = true
    },
    successApplyReceiptDiscount(state) {
      state.applyReceiptDiscount.loading = false
      state.applyReceiptDiscount.error = null
    },
    failureApplyReceiptDiscount(
      state,
      { payload }: PayloadAction<{ err: PostError<{ stage: any }> }>,
    ) {
      state.applyReceiptDiscount.loading = false
      state.applyReceiptDiscount.error = payload.err
    },
    resetApplyReceiptDiscount(state) {
      state.applyReceiptDiscount = {
        loading: false,
        error: null,
      }
    },
    setUserInfo(state, { payload }: PayloadAction<UserType>) {
      state.user = payload
    },
    requestEventInfo(state) {
      state.eventInfo.loading = true
    },
    successEventInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: EventInfoResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.eventInfo
      if (data) {
        cached[key] = { data, error: null }
      }
      state.eventInfo.loading = false
    },
    failureEventInfo(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.eventInfo
      cached[key] = { data: null, error: err }

      state.eventInfo.loading = false
    },
    requestPurchasedSubscriptionInfo(state) {
      state.purchasedSubscriptionInfo.loading = true
      state.purchasedSubscriptionInfo.data = null
      state.purchasedSubscriptionInfo.error = null
    },
    successPurchasedSubscriptionInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: PurchasedSubscriptionInfoResponseData }>,
    ) {
      const { data } = payload
      if (data) {
        state.purchasedSubscriptionInfo.data = data
      }
      state.purchasedSubscriptionInfo.loading = false
      state.purchasedSubscriptionInfo.error = null
    },
    failurePurchasedSubscriptionInfo(
      state,
      { payload }: PayloadAction<{ key: string; err: Error }>,
    ) {
      state.purchasedSubscriptionInfo.loading = false
      state.purchasedSubscriptionInfo.data = null
      state.purchasedSubscriptionInfo.error = payload.err
    },
    requestPartnerPromotions(state) {
      state.partnerPromotions.loading = true
      state.partnerPromotions.data = null
      state.partnerPromotions.error = null
    },
    successPartnerPromotions(state, { payload }: PayloadAction<{ data?: PartnerPromotion[] }>) {
      const { data } = payload
      if (data) {
        state.partnerPromotions.data = data
      }
      state.partnerPromotions.loading = false
      state.partnerPromotions.error = null
    },
    failurePartnerPromotions(state, { payload }: PayloadAction<{ err: Error }>) {
      state.partnerPromotions.loading = false
      state.partnerPromotions.data = null
      state.partnerPromotions.error = payload.err
    },
    successMobileApps(state, { payload }: PayloadAction<{ data?: MobileApps }>) {
      const { data } = payload
      if (data) {
        state.mobileApps.data = data
      }
      state.mobileApps.error = null
    },
    failureMobileApps(state, { payload }: PayloadAction<{ err: Error }>) {
      state.mobileApps.data = null
      state.mobileApps.error = payload.err
    },
    requestSavePurchasedSubscription(state) {
      state.savePurchasedSubscription.loading = true
      state.savePurchasedSubscription.error = null
    },
    successSavePurchasedSubscription(state) {
      state.savePurchasedSubscription.loading = false
      state.savePurchasedSubscription.error = null
    },
    failureSavePurchasedSubscription(state, { payload }: PayloadAction<{ err: Error }>) {
      state.savePurchasedSubscription.loading = false
      state.savePurchasedSubscription.error = payload.err
    },
    requestFinesInfo(state) {
      state.finesInfo.loading = true
    },
    successFinesInfo(
      state,
      { payload }: PayloadAction<{ key: string; data?: FinesInfoResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.finesInfo
      if (data) {
        cached[key] = { data, error: null }
      }
      state.finesInfo.loading = false
    },
    failureFinesInfo(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.finesInfo
      cached[key] = { data: null, error: err }

      state.finesInfo.loading = false
    },
    resetFinesInfo(state) {
      state.finesInfo = {
        cached: {},
        loading: false,
        error: null,
      }
    },
    requestPaymentReceipt(state) {
      state.paymentReceipt.loading = true
    },
    successPaymentReceipt(
      state,
      { payload }: PayloadAction<{ key: string; data?: PaymentReceiptResponseData }>,
    ) {
      const { key, data } = payload
      const { cached } = state.paymentReceipt
      if (data) {
        cached[key] = { data, error: null }
      }
      state.paymentReceipt.loading = false
    },
    failurePaymentReceipt(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state.paymentReceipt
      cached[key] = { data: null, error: err }
      state.paymentReceipt.loading = false
    },
  },
})

export const fetchAreaInfo = ({
  id,
  onSuccess,
  onError,
}: {
  id: string | number
  onSuccess?: (data: AreaInfoResponseData) => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${id}`

  try {
    dispatch(requestAreaInfo())
    const { cached } = getState().stages.areaInfo

    if (cached[key] && cached[key].data !== null) {
      dispatch(successAreaInfo({ key }))
      if (onSuccess) onSuccess(cached[key].data as AreaInfoResponseData)
      return
    }

    const params = { ':areaId': id }
    const response: AreaInfoSuccess | AreaInfoError = await api.areas({ params })

    if (isAreaInfoSuccess(response)) {
      dispatch(successAreaInfo({ key, data: response.data }))
      if (onSuccess) onSuccess(response.data)
    } else {
      dispatch(failureAreaInfo({ key, err: { statusCode: response.statusCode } }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(
      failureAreaInfo({
        key,
        err: { statusCode: 500 },
      }),
    )
    if (onError) onError()
  }
}

export const fetchDiscountAreaInfo = ({
  areaId,
  token,
}: {
  areaId: string
  token: string
}): AppThunk => async (dispatch, _, { api }) => {
  try {
    const response = await api.checkDiscountPage({
      token,
      areaId,
    })()
    dispatch(successDiscountAreaInfo({ data: response.data }))
  } catch (err) {
    dispatch(failureDiscountInfo())
  }
}

export const fetchAreaSubscriptions = ({
  areaId,
  onSuccess,
  onError,
}: {
  areaId: string
  onSuccess?: (data: AreaSubscriptionsResponseData) => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${areaId}`

  try {
    dispatch(requestAreaSubscriptions())
    const { cached } = getState().stages.areaSubscriptions

    if (cached[key] && cached[key].data !== null) {
      dispatch(successAreaSubscriptions({ key }))
      if (onSuccess) onSuccess(cached[key].data as AreaSubscriptionsResponseData)
      return
    }

    const params = { ':areaId': areaId }
    const response: AreaSubscriptionsSuccess | AreaSubscriptionsError = await api.subscriptions({
      params,
    })

    if (isAreaSubscriptionsSuccess(response)) {
      dispatch(successAreaSubscriptions({ key, data: response.data }))
      if (onSuccess) onSuccess(response.data)
    } else {
      dispatch(failureAreaSubscriptions({ key, err: { statusCode: response.statusCode } }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(
      failureAreaSubscriptions({
        key,
        err: { statusCode: 500 },
      }),
    )
    if (onError) onError()
  }
}

export const fetchCars = ({
  number,
  areaId,
  onSuccess,
  onError,
}: {
  number: string
  areaId: string
  onSuccess?: (data: CarsResponseData) => void
  onError?: (error: PostError<FindCarFields>) => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = number

  try {
    dispatch(requestCars())
    const { cached } = getState().stages

    if (cached[key] && cached[key].data !== null) {
      dispatch(successCars({ key }))
      if (onSuccess) onSuccess(cached[key].data as CarsResponseData)
      return
    }

    const params = { number, ':areaId': areaId, before_pay_subscription: 'yes' }
    const response: ResponseSuccess | ResponseError = await api.searchCar({ params })

    if (isResponseSuccess(response)) {
      dispatch(successCars({ key, data: response.data }))
      if (onSuccess) onSuccess(response.data)
    } else {
      dispatch(failureCars({ key, err: response.data?.errors }))
      if (onError) onError(response.data?.errors)
    }
  } catch (err) {
    const error = { catched: ['Ошибка на сервере. Попробуйте повторить запрос позже.'] }
    dispatch(
      failureCars({
        key,
        err: error,
      }),
    )
    if (onError) onError(error)
  }
}

export const fetchStageByStrictSearch = ({
  number,
  areaId,
  onSuccess,
  onError,
}: {
  number: string
  areaId: string
  onSuccess?: (data: any) => void
  onError?: (error: PostError<FindCarFields>) => void
}): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestCars())
    const { cached } = getState().stages
    console.log('cached', cached)

    const params = { number, ':areaId': areaId, before_pay_subscription: 'yes' }
    const response:
      | ResponseSearchStageByStrictSearchSuccess
      | ResponseError = await api.searchCarByStrictNumber({ params })

    if (isResponseStrictSearchStageSuccess(response)) {
      if (onSuccess) onSuccess(response.data)
    } else {
      if (onError) onError(response.data?.errors)
    }
  } catch (err) {
    const error = { catched: ['Ошибка на сервере. Попробуйте повторить запрос позже.'] }
    if (onError) onError(error)
  }
}

export const checkPayment = ({
  token,
  onSuccess,
  onError,
}: {
  token: string
  onSuccess?: ({ status }: { status: PaymentStatus }) => void
  onError?: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  try {
    dispatch(requestCheckPayment())

    const params = { ':token': token }
    const response: PaymentCheckSuccess | PaymentCheckError = await api.payment({
      params,
    })

    if (isPaymentCheckResultSuccess(response)) {
      dispatch(successCheckPayment(response.data))
      if (onSuccess) onSuccess({ status: response.data.status })
    } else {
      dispatch(failureCheckPayment())
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureCheckPayment())
    if (onError) onError()
  }
}

export const postApplyDiscountRequest = ({
  stage_id,
  number,
  token,
  email,
  new_number,
  old_number,
  onSuccess,
  onError,
}: {
  stage_id: number
  number?: string
  token: string
  new_number?: string
  old_number?: string
  email?: string
  onSuccess?: (data: DiscountSuccessResponse) => void
  onError?: (e?: DiscountErrorResponse) => void
}): AppThunk => async (dispatch, _, { api }) => {
  try {
    dispatch(requestStartDiscount())

    const data = { stage_id, token, number, email, old_number, new_number }
    const response: any = await api.applyDiscount({
      data,
    })

    if (response.data) {
      dispatch(successDiscount({ data: response.data }))
      if (onSuccess) onSuccess(response)
    } else {
      dispatch(failureDiscountInfo(response))
      const errors = response?.errors
      if (onError && errors) onError(errors)
    }
  } catch (err) {
    dispatch(failureDiscountInfo())
    if (onError) onError()
  }
}

export const startPayment = ({
  id,
  onSuccess,
  onError,
}: {
  id: string | number
  onSuccess?: ({ data }: { data: StartPaymentResponseData }) => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestStartPayment())

    const number_car = getCookie(NUMBER_CAR)
    const userEmail = getUserEmail(getState())
    const data = { ':stageId': id, email: userEmail, number: number_car }
    const response: StartPaymentSuccess | StartPaymentError = await api.startPayment({
      data,
    })

    if (isStartPaymentSuccess(response)) {
      dispatch(successStartPayment({ data: response.data }))
      if (onSuccess) onSuccess({ data: response.data })
    } else {
      dispatch(failureStartPayment())
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureStartPayment())
    if (onError) onError()
  }
}

export const startSubscriptionPayment = ({
  subscriptionId,
  number,
  user,
  onSuccess,
  onError,
}: {
  subscriptionId: string | number
  number: string
  onSuccess?: ({ data }: { data: StartPaymentResponseData }) => void
  onError?: (message: string) => void
  user: UserType | null
}): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestStartPayment())
    const birthday = user?.birthday ? { birthday: user?.birthday?.replaceAll('/', '.') } : {}
    const passport_date = user?.passport_date
      ? { passport_date: user?.passport_date?.replaceAll('/', '.') }
      : {}
    const permissions = user?.permissions
      ? { permissions: { data_processing: true, parking_rules: true } }
      : {}
    const userEmail = getUserEmail(getState())
    const data = {
      ...user,
      ':subscriptionId': subscriptionId,
      email: userEmail,
      number,
      ...birthday,
      ...passport_date,
      ...permissions,
    }

    const response: any = await api.startSubscriptionPayment({
      data,
    })

    if (isStartPaymentSuccess(response)) {
      dispatch(successStartPayment({ data: response.data }))
      if (onSuccess) onSuccess({ data: response.data })
    } else {
      dispatch(failureStartPayment())
      if (onError) onError(response?.message)
    }
  } catch (err) {
    dispatch(failureStartPayment())
  }
}

export const fetchPaymentInfo = ({
  id,
  number,
  onSuccess,
  onError,
}: {
  id: string | number
  number?: string | number
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${id}`

  try {
    dispatch(requestPaymentInfo())
    const { cached } = getState().stages.paymentInfo

    if (cached[key]) {
      dispatch(successPaymentInfo({ key }))
      if (onSuccess) onSuccess()
      return
    }
    const params = { ':stageId': id, number }
    const response: PaymentInfoSuccess | PaymentInfoError = await api.sessionInfo({ params })

    if (isPaymentInfoSuccess(response)) {
      dispatch(successPaymentInfo({ key, data: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failurePaymentInfo({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failurePaymentInfo({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const fetchFinesInfo = ({
  id,
  onSuccess,
  onError,
}: {
  id: string | number
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${id}`

  try {
    dispatch(requestFinesInfo())
    const { cached } = getState().stages.finesInfo

    if (cached[key]) {
      dispatch(successFinesInfo({ key }))
      if (onSuccess) onSuccess()
      return
    }
    const params = { ':stageId': id }
    const response: FinesInfoSuccess | FinesInfoError = await api.finesInfo({ params })

    if (isFinesInfoSuccess(response)) {
      dispatch(successFinesInfo({ key, data: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureFinesInfo({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureFinesInfo({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const fetchSubscriptionPaymentInfo = ({
  subscriptionId,
  onSuccess,
  onError,
}: {
  subscriptionId: string
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${subscriptionId}`

  try {
    dispatch(requestSubscriptionInfo())
    const { cached } = getState().stages.subscriptionInfo

    if (cached[key]) {
      dispatch(successSubscriptionInfo({ key }))
      if (onSuccess) onSuccess()
      return cached[key].data?.subscription.additional_fields
    }
    const params = { ':subscriptionId': subscriptionId }
    const response: SubscriptionInfoSuccess | SubscriptionInfoError = await api.subscriptionInfo({
      params,
    })

    if (isSubscriptionInfoSuccess(response)) {
      dispatch(successSubscriptionInfo({ key, data: response.data }))
      if (onSuccess) onSuccess()
      return response?.data.subscription.additional_fields
    } else {
      dispatch(failureSubscriptionInfo({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureSubscriptionInfo({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const fetchPurchasedSubscriptionInfo = ({
  token,
  onSuccess,
  onError,
}: {
  token: string
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  const key = `${token}`
  try {
    dispatch(requestPurchasedSubscriptionInfo())

    const params = { ':token': token }
    const response:
      | PurchasedSubscriptionInfoSuccess
      | PurchasedSubscriptionInfoError = await api.purchasedSubscription({
      params,
    })

    if (isPurchasedSubscriptionInfoSuccess(response)) {
      dispatch(successPurchasedSubscriptionInfo({ key, data: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failurePurchasedSubscriptionInfo({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failurePurchasedSubscriptionInfo({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const savePurchasedSubscriptionInfo = ({
  token,
  additionalFields,
  onSuccess,
  onError,
}: {
  token: string
  additionalFields: any
  onSuccess: () => void
  onError: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  try {
    dispatch(requestSavePurchasedSubscription())
    const data = {
      ':token': token,
      additional_fields: additionalFields,
    }
    const response:
      | SavePurchasedSubscriptionSuccess
      | SavePurchasedSubscriptionError = await api.saveSubscriptionFields({
      data,
    })

    if (isSavePurchasedSubscriptionSuccess(response)) {
      dispatch(successSavePurchasedSubscription())
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureSavePurchasedSubscription({ err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureSavePurchasedSubscription({ err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const applyReceiptDiscount = ({
  stageId,
  receiptData,
  commonError,
  onSuccess,
  onError,
}: {
  stageId: string | number
  receiptData: string
  commonError: { stage: string[] }
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  try {
    dispatch(requestApplyReceiptDiscount())

    const data = { ':stageId': stageId, receipt_data: receiptData }
    const response:
      | ApplyReceiptDiscountSuccess
      | ApplyReceiptDiscountError = await api.applyReceiptDiscount({
      data,
    })

    if (isApplyReceiptDiscountSuccess(response)) {
      dispatch(successApplyReceiptDiscount())
      if (onSuccess) onSuccess()
    } else {
      dispatch(
        failureApplyReceiptDiscount({
          err: response.errors ? response.errors : commonError,
        }),
      )
      if (onError) onError()
    }
  } catch (err) {
    dispatch(
      failureApplyReceiptDiscount({
        err: commonError,
      }),
    )
    if (onError) onError()
  }
}

export const fetchPaymentReceipt = ({
  orderNumber,
  onSuccess,
  onError,
}: {
  orderNumber: string
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, _getState, { api }) => {
  const key = `${orderNumber}`

  try {
    const params = { ':externalOrderNumber': orderNumber }
    const response: PaymentReceiptSuccess | PaymentReceiptError = await api.paymentReceipt({
      params,
    })

    if (isPaymentReceiptSuccess(response)) {
      dispatch(successPaymentReceipt({ key, data: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failurePaymentReceipt({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failurePaymentReceipt({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const fetchPartnerPromotions = (): AppThunk => async (dispatch, getState, { api }) => {
  const { data: partnerPromotionsData } = getState().stages.partnerPromotions
  if (partnerPromotionsData) return

  try {
    const params = {
      with: ['bannerImage'],
      platform: 'desktop',
    }

    const response: PartnerPromotionsSuccess | PartnerPromotionsError = await api.partnerPromotions(
      {
        params,
      },
    )

    if (isPartnerPromotionsSuccess(response)) {
      dispatch(successPartnerPromotions({ data: response.data }))
    } else {
      dispatch(failurePartnerPromotions({ err: response }))
    }
  } catch (err) {
    dispatch(failurePartnerPromotions({ err: { statusCode: 500 } }))
  }
}

export const fetchMobileApps = (): AppThunk => async (dispatch, getState, { api }) => {
  const { data: mobileAppsData } = getState().stages.mobileApps
  if (mobileAppsData) return

  try {
    const response: MobileAppsSuccess | MobileAppsError = await api.mobileApps()

    if (isMobileAppsSuccess(response)) {
      dispatch(successMobileApps({ data: response.data }))
    } else {
      dispatch(failureMobileApps({ err: response }))
    }
  } catch (err) {
    dispatch(failureMobileApps({ err: { statusCode: 500 } }))
  }
}

export const getDiscountInfoById = createCachedSelector(
  (state: RootState) => state.stages.discountInfo,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  results => ({
    data: results.data ?? null,
    errors: results.errors ?? null,
    loading: results.loading,
  }),
)((_state, id) => `${id}` ?? '')

export const getDiscountAreaById = createCachedSelector(
  (state: RootState) => state.stages.discountAreaInfo,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  results => ({
    data: results.data ?? null,
    errors: results.errors ?? null,
    loading: results.loading,
  }),
)((_state, id) => `${id}` ?? '')

export const getCarsByNumber = createCachedSelector(
  (state: RootState) => state.stages,
  (_state: RootState, number: string | null) => number ?? '',
  (stages, number) => ({
    data: stages.cached[number]?.data ?? null,
    loading: stages.loading,
    error: stages.cached[number]?.error ?? null,
  }),
)((_state, number) => number ?? '')

export const getPaymentInfoById = createCachedSelector(
  (state: RootState) => state.stages.paymentInfo,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
  }),
)((_state, id) => `${id}` ?? '')

export const getFinesInfoById = createCachedSelector(
  (state: RootState) => state.stages.finesInfo,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
  }),
)((_state, id) => `${id}` ?? '')

export const getApplyReceiptDiscountState = createSelector(
  (state: RootState) => state.stages.applyReceiptDiscount,
  applyReceiptDiscount => applyReceiptDiscount,
)

export const getSubscriptionInfoById = createCachedSelector(
  (state: RootState) => state.stages.subscriptionInfo,
  (_state: RootState, id: string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
  }),
)((_state, id) => `${id}` ?? '')

export const getAreaInfoById = createCachedSelector(
  (state: RootState) => state.stages.areaInfo,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  (results, id) => {
    return {
      data: results.cached[id]?.data ?? null,
      loading: results.loading,
      error: results.cached[id]?.error ?? null,
    }
  },
)((_state, id) => `${id}` ?? '')

export const getPurchasedSubscriptionInfo = createSelector(
  (state: RootState) => state.stages,
  stages => stages.purchasedSubscriptionInfo,
)

export const getSavePurchasedSubscription = createSelector(
  (state: RootState) => state.stages,
  stages => stages.savePurchasedSubscription,
)

export const getPartnerPromotions = createSelector(
  (state: RootState) => state.stages,
  stages => stages.partnerPromotions,
)

export const getMobileApps = createSelector(
  (state: RootState) => state.stages,
  stages => stages.mobileApps,
)

export const getSubscriptionsByAreaId = createCachedSelector(
  (state: RootState) => state.stages.areaSubscriptions,
  (_state: RootState, id: string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
    error: results.cached[id]?.error ?? null,
  }),
)((_state, id) => `${id}` ?? '')

export const getSelectedId = createSelector(
  (state: RootState) => state.stages,
  stages => stages.selectedId,
)

export const getUserEmail = createSelector(
  (state: RootState) => state.stages,
  stages => stages.userEmail,
)

export const getPaymentState = createSelector(
  (state: RootState) => state.stages.payment,
  payment => {
    const { loading, data } = payment
    return { loading, data }
  },
)

export const getUserInformation = createSelector(
  (state: RootState) => state.stages,
  stages => stages.user,
)

export const getEventsByAreaId = createCachedSelector(
  (state: RootState) => state.stages.areaEvents,
  (_state: RootState, id: string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
    error: results.cached[id]?.error ?? null,
  }),
)((_state, id) => `${id}` ?? '')

export const getPaymentReceiptByOrderNumber = createCachedSelector(
  (state: RootState) => state.stages.paymentReceipt,
  (_state: RootState, id: number | string | null) => `${id}` ?? '',
  (results, id) => ({
    data: results.cached[id]?.data ?? null,
    loading: results.loading,
    error: results.cached[id]?.error,
  }),
)((_state, id) => `${id}` ?? '')

export const fetchAreaEvents = ({
  areaId,
  onSuccess,
  onError,
}: {
  areaId: string
  onSuccess?: (data: AreaEventsResponseData) => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${areaId}`

  try {
    dispatch(requestAreaEvents())
    const { cached } = getState().stages.areaEvents

    if (cached[key] && cached[key].data !== null) {
      dispatch(successAreaEvents({ key }))
      if (onSuccess) onSuccess(cached[key].data as AreaEventsResponseData)
      return
    }

    const params = { ':areaId': areaId }
    const response: AreaEventsSuccess | AreaEventsError = await api.events({
      params,
    })

    if (isAreaEventsSuccess(response)) {
      dispatch(successAreaEvents({ key, data: response.data }))
      if (onSuccess) onSuccess(response.data)
    } else {
      dispatch(failureAreaEvents({ key, err: { statusCode: response.statusCode } }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(
      failureAreaEvents({
        key,
        err: { statusCode: 500 },
      }),
    )
    if (onError) onError()
  }
}

export const fetchEventPaymentInfo = ({
  eventId,
  onSuccess,
  onError,
}: {
  eventId: string
  onSuccess?: () => void
  onError?: () => void
}): AppThunk => async (dispatch, getState, { api }) => {
  const key = `${eventId}`

  try {
    dispatch(requestEventInfo())
    const { cached } = getState().stages.eventInfo

    if (cached[key]) {
      dispatch(successEventInfo({ key }))
      if (onSuccess) onSuccess()
      return
    }
    const params = { ':eventId': eventId }
    const response: EventInfoSuccess | EventInfoError = await api.eventInfo({
      params,
    })

    if (isEventInfoSuccess(response)) {
      dispatch(successEventInfo({ key, data: response.data }))
      if (onSuccess) onSuccess()
    } else {
      dispatch(failureEventInfo({ key, err: response }))
      if (onError) onError()
    }
  } catch (err) {
    dispatch(failureEventInfo({ key, err: { statusCode: 500 } }))
    if (onError) onError()
  }
}

export const getEventInfoById = createCachedSelector(
  (state: RootState) => state.stages.eventInfo,
  (_state: RootState, id: string | null) => `${id}` ?? '',
  (results, id) => {
    return {
      data: results.cached[id]?.data ?? null,
      loading: results.loading,
    }
  },
)((_state, id) => `${id}` ?? '')

export const startEventPayment = ({
  eventId,
  number,
  onSuccess,
  onError,
}: {
  eventId: string | number
  number: string
  onSuccess?: ({ data }: { data: StartPaymentResponseData }) => void
  onError?: (message: string) => void
}): AppThunk => async (dispatch, getState, { api }) => {
  try {
    dispatch(requestStartPayment())
    const userEmail = getUserEmail(getState())
    const payload = {
      ':eventId': eventId,
      email: userEmail,
      number,
      permissions: { parking_rules: true },
    }

    const response: any = await api.startEventPayment({
      data: payload,
    })

    if (isStartPaymentSuccess(response)) {
      dispatch(successStartPayment({ data: response.data }))
      if (onSuccess) onSuccess({ data: response.data })
    } else {
      dispatch(failureStartPayment())
      if (onError) onError(response.errors?.number[0])
    }
  } catch (err) {
    dispatch(failureStartPayment())
  }
}

export const {
  requestAreaInfo,
  successAreaInfo,
  failureAreaInfo,
  changeSelectedId,
  changeUserEmail,
  requestCars,
  successCars,
  failureCars,
  requestPaymentInfo,
  successPaymentInfo,
  failurePaymentInfo,
  resetPaymentInfo,
  requestStartPayment,
  successStartPayment,
  failureStartPayment,
  requestStartDiscount,
  failureDiscountInfo,
  requestCheckPayment,
  successDiscount,
  successDiscountAreaInfo,
  successCheckPayment,
  failureCheckPayment,
  requestAreaSubscriptions,
  requestAreaEvents,
  successAreaEvents,
  failureAreaEvents,
  successAreaSubscriptions,
  failureAreaSubscriptions,
  requestSubscriptionInfo,
  successSubscriptionInfo,
  failureSubscriptionInfo,
  requestEventInfo,
  successEventInfo,
  failureEventInfo,
  setUserInfo,
  requestPurchasedSubscriptionInfo,
  successPurchasedSubscriptionInfo,
  failurePurchasedSubscriptionInfo,
  requestSavePurchasedSubscription,
  successSavePurchasedSubscription,
  failureSavePurchasedSubscription,
  requestFinesInfo,
  successFinesInfo,
  failureFinesInfo,
  resetFinesInfo,
  requestApplyReceiptDiscount,
  successApplyReceiptDiscount,
  failureApplyReceiptDiscount,
  resetApplyReceiptDiscount,
  requestPaymentReceipt,
  successPaymentReceipt,
  failurePaymentReceipt,
  successPartnerPromotions,
  failurePartnerPromotions,
  successMobileApps,
  failureMobileApps,
} = stages.actions

export default stages.reducer
