import { Title, Container, Paragraph, Button, FixedBottom, HalvaBanner } from '@/components'
import styles from './PaymentResult.module.scss'
import getLinkHref from '@/helpers/getLinkHref'
import Link from 'next/link'

export default function PaymentSubscriptionSuccessPage({
  paidUntil,
  area,
}: {
  paidUntil?: string | null
  area: {
    id: string
    customising?: { button_back_color: string | null }
  } | null
}) {
  const areaHref = getLinkHref({ type: 'area', data: { id: area?.id } })

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <HalvaBanner />
        <div className={styles.successResultContent}>
          <img
            className={styles.sign__Error__icon}
            src="/images/payment-success.svg"
            alt="logo"
            width={87}
            height={87}
          />

          <Title align="center">Абонемент куплен!</Title>

          <div className={styles.subscriptionInfo}>
            {paidUntil && (
              <Paragraph align="center" className={styles.section__descr}>
                Действителен до {paidUntil}.
              </Paragraph>
            )}
            <Paragraph align="center" className={styles.section__text}>
              Держите номер авто чистым для автоматического открытия шлагбаума.
            </Paragraph>
          </div>
        </div>

        <FixedBottom isHide>
          <div className={styles.button}>
            <Link href={areaHref} passHref>
              <Button
                component="a"
                className={styles.button}
                background={area?.customising?.button_back_color}
              >
                На главную
              </Button>
            </Link>
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
