import cn from 'classnames'
import {
  getPaymentState,
  getAreaInfoById,
  changeUserEmail,
  getEventInfoById,
  startEventPayment,
} from '@/store/slices/stagesSlice/stagesSlice'
import { Container, Button, Card, Title, Text, Paragraph, CheckBox } from '@/components';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store'
import { useRouter } from 'next/router'
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import CarNumberLabel from '../PaymentPage/CarNumberLabel'

import styles from './EventPaymentPage.module.scss'
import EmailInput from '@/components/ui/EmailInput'
import { supportPhoneLink } from '@/constants';
import { formatEventPeriod } from '@/helpers/formatEventPeriod';

export default function EventPaymentPage({ eventId, number }: { eventId: string; number: string }) {
  const fieldRef = useRef(null)
  const reduxState = useSelector<RootState, RootState>(state => state)
  const dispatch = useDispatch()
  const router = useRouter()
  const { loading } = getPaymentState(reduxState)
  const [agreeLinkParkingRules, setAgreeLinkParkingRules] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [emailInputError, setEmailInputError] = useState('')
  const { data: areaInfo } = getAreaInfoById(reduxState, (router.query?.areaId as string) || null)
  const { data: eventInfo } = getEventInfoById(reduxState, eventId)
  const areaInn = areaInfo?.inn ?? ''
  const areaLegalName = areaInfo?.legal_name ?? ''
  const isSubmitDisabled = areaInfo?.link_parking_rules ? emailValue?.length < 6 || !agreeLinkParkingRules : emailValue?.length < 6
  const period =
    eventInfo?.start_at && eventInfo?.end_at
      ? formatEventPeriod(eventInfo.start_at, eventInfo.end_at)
      : null

  const handleStartPayment = useCallback(async () => {
    if (emailValue && !emailValue.match(/.+[@].+/gm)) {
      setEmailInputError('Укажите корректный email')
      return
    }
    const email = emailValue.trim()
    await dispatch(changeUserEmail({ email }))
    await dispatch(
      startEventPayment({
        eventId,
        number,
        onSuccess: ({ data }) => router.push(data.url),
        onError: (message: string) => setError(message),
      }),
    )
  }, [eventId, number, emailValue])

  useEffect(() => {
    if (emailInputError) {
      const field = (fieldRef.current as unknown) as HTMLInputElement
      field?.focus()
    }
  }, [emailInputError])

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleStartPayment()
    }
  }

  if (!eventId)
    return (
      <Container>
        <Card color="decor">
          <div className={styles.notFound}>
            <div className={styles.notFound_Image}>
              <img src="/images/subscription-not-found.svg" alt="" />
            </div>
            <span>Нет информации по данному абонементу</span>
          </div>
        </Card>
      </Container>
    )

  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <Container>
          <header className={styles.header}>
            <Title className={styles.price}>{eventInfo?.amount} ₽</Title>
            <Paragraph className={styles.interval}>{period}</Paragraph>
            <CarNumberLabel number={number} className={styles.carNumber} />
          </header>
        </Container>

        <Container className={styles.email_container}>
          <Title className={styles.content__title} level={4} size={24}>
            Укажите ваш email
          </Title>

          <Text level={2} className={styles.content__paragraph}>
            Ваш email необходим, чтобы мы могли прислать чек об успешной оплате.
          </Text>

          <label className={cn(styles.input, { [styles['input--active']]: !!emailValue })}>
            <EmailInput
              onChange={setEmailValue}
              onKeyPress={handleKeyPress}
              value={emailValue}
              error={emailInputError || error}
            />
          </label>
        </Container>

        <Container>
          <div className={styles.requisitesWrapper}>
            <div className={styles.requisites}>
              <span>{areaLegalName}</span>
              <span>ИНН {areaInn}</span>
            </div>
            {areaInfo?.link_parking_rules &&
              <CheckBox
                name='agreeLinkParkingRules'
                checked={agreeLinkParkingRules}
                title={`<span>Я согласен с <a href=${areaInfo.link_parking_rules} rel='noreferrer noopener' target='_blank'>Правилами пользования парковкой</a></span>`}
                onChange={() => setAgreeLinkParkingRules(!agreeLinkParkingRules)}
              />
            }
          </div>
        </Container>
      </div>

      <Container className={styles.buttons}>
        <Button
          disabled={isSubmitDisabled}
          withLoader
          onClick={handleStartPayment}
          className={styles.button}
          loading={loading}
          background={areaInfo?.customising.button_back_color}
        >
          К оплате
        </Button>
        <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
          Обратиться в поддержку
        </Button>
      </Container>
    </section>
  )
}
