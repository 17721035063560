import * as React from 'react'

const SVG = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 38H22V46L17 43L12 46V38ZM40 2H24V46L29 43L34 46V42H40C41.0609 42 42.0783 41.5786 42.8284 40.8284C43.5786 40.0783 44 39.0609 44 38V6C44 4.93913 43.5786 3.92172 42.8284 3.17157C42.0783 2.42143 41.0609 2 40 2ZM28 10H32V14H28V10ZM28 20H32V24H28V20ZM28 30H32V34H28V30ZM8 2C6.94011 2.00317 5.92453 2.42561 5.17507 3.17507C4.42561 3.92453 4.00317 4.94011 4 6V38C4.00317 39.0599 4.42561 40.0755 5.17507 40.8249C5.92453 41.5744 6.94011 41.9968 8 42V6H22V2H8Z"
      fill="white"
    />
    <path d="M12 10H16V14H12V10ZM12 20H16V24H12V20ZM12 30H16V34H12V30Z" fill="white" />
  </svg>
)

export default SVG
