import { Title } from '@/components'
import styles from '../PaymentReceiptPage.module.scss'
import { supportPhoneLink } from '@/constants';

export default function PaymentConfirmation({ timer }: { timer: string }) {
  return (
    <div>
      <div className={styles.titleWithTimer}>
        <Title level={2} className={styles.subtitle}>
          Идет процесс подтверждения платежа
        </Title>
        <span className={styles.timer}>{timer}</span>
      </div>
      <p className={styles.description}>
        Если по истечении времени таймера вы не получили чек, то попробуйте перезагрузить страницу
        или{' '}
        <a href={supportPhoneLink} className={styles.link}>
          обратитесь в поддержку
        </a>
        .
      </p>
    </div>
  )
}
