import { Title, Container, Paragraph, Button, FixedBottom } from '@/components'
import styles from './PaymentResult.module.scss'
import getLinkHref from '@/helpers/getLinkHref'
import cn from 'classnames'
import Link from 'next/link'
import { supportPhone, supportPhoneLink } from '@/constants';

export default function PaymentErrorPage({
  area,
}: {
  area: {
    id: string
    customising?: { button_back_color: string | null }
  } | null
}) {
  const areaHref = getLinkHref({ type: 'area', data: { id: area?.id } })

  return (
    <section className={cn(styles.section, styles.section__Error)}>
      <Container className={cn(styles.container, styles.container_isErrorPage)}>
        <div>
          <img
            className={styles.sign__Error__icon}
            src="/images/payment-failed.svg"
            alt="logo"
            width={87}
            height={87}
          />
          <Title align="center" className={styles.sign__Error__title}>
            Оплата не прошла
          </Title>
          <Paragraph className={styles.sign__Error__descr}>К сожалению оплата не прошла</Paragraph>
        </div>

        <FixedBottom>
          {!!area ? (
            <div className={styles.buttons}>
              <Link href={areaHref} passHref>
                <Button
                  component="a"
                  className={styles.button}
                  background={area?.customising?.button_back_color}
                >
                  Попробовать снова
                </Button>
              </Link>
              <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
                Обратиться в поддержку
              </Button>
            </div>
          ) : (
            <div className={styles.footerMessage}>
              Номер технической поддержки сервиса HippoParking:{' '}
              <a className={styles.footer__message_isDesktop} href={supportPhoneLink}>
                {supportPhone}
              </a>
            </div>
          )}
        </FixedBottom>
      </Container>
    </section>
  )
}
