import { Title, Container, FixedBottom, Button, Paragraph, QrScanner } from '@/components'
import { useState, useEffect } from 'react'
import {
  applyReceiptDiscount,
  getApplyReceiptDiscountState,
  resetApplyReceiptDiscount,
  getAreaInfoById,
} from '@/store/slices/stagesSlice/stagesSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { QrScannerState } from '@/components/QrScanner/QrScanner'
import { RootState } from '@/store'
import cn from 'classnames'
import getLinkHref from '@/helpers/getLinkHref'
import { supportPhone, supportPhoneLink } from '@/constants';
import { commonError } from './ApplyReceiptDiscountPage.constants'
import styles from './ApplyReceiptDiscountPage.module.scss'

export default function ApplyReceiptDiscountPage({
  areaId,
  stageId,
}: {
  areaId: string
  stageId: string
}) {
  const router = useRouter()
  const dispatch = useDispatch()
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)
  const { error } = getApplyReceiptDiscountState(reduxState)
  const qrError = error?.stage ? error.stage[0] : null

  const [scanner, setScanner] = useState<{ state: QrScannerState; result: string | null }>({
    state: 'scanning',
    result: null,
  })

  const onScan = async (result: string) => {
    setScanner({ state: 'success', result })

    await dispatch(
      applyReceiptDiscount({
        stageId,
        receiptData: result,
        commonError,
        onSuccess: () => {
          const paymentHref = getLinkHref({ type: 'payment', data: { areaId, stageId } })
          router.push(paymentHref)
        },
        onError: () => {
          setScanner(prev => ({ ...prev, state: 'scanning' }))
        },
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetApplyReceiptDiscount())
    }
  }, [])

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Title>Применение скидки</Title>
        <Paragraph className={styles.section__text}>
          Для применения скидки, пожалуйста, отсканируйте QR-код
        </Paragraph>
        <Paragraph className={styles.section__text}>
          Убедитесь, что вы предоставили доступ к камере для этой страницы.
        </Paragraph>

        <div className={styles.scanner}>
          <QrScanner state={scanner.state} onScanSuccess={onScan} />
        </div>

        {qrError && <div className={cn(styles.scanInfo, styles.scanInfo__Error)}>{qrError}</div>}

        <FixedBottom>
          <div className={styles.button}>
            <Button
              type="button"
              onClick={router.back}
              background={areaInfo?.customising.button_back_color}
            >
              Назад
            </Button>
          </div>
          <div className={cn(styles.footerMessage, styles.footerMessageWrapper)}>
            Номер технической поддержки сервиса HippoParking:{' '}
            <a href={supportPhoneLink}>{supportPhone}</a>
          </div>
        </FixedBottom>
      </Container>
    </section>
  )
}
