import { MobileApps } from '@/store/slices/stagesSlice/interfaces'
import { yandexMetrika } from '@/helpers/yandexMetrika';
import styles from './AreaPageHeader.module.scss'

const storeLinks = {
  appStore: 'https://www.apple.com/app-store/',
  googlePlay: 'https://play.google.com/store/apps',
}

export const AreaPageHeader = ({ mobileApps }: { mobileApps: MobileApps | null }) => {
  return (
    <header className={styles.header}>
      <h2 className={styles.header__title}>Мобильное приложение HippoParking</h2>
      <p className={styles.header__subtitle}>
        Скачайте приложение и оплачивайте в 1 клик. Продлевайте абонемент на парковку и следите за
        историей парковочных сессий и платежей.
      </p>
      <div className={styles.header__mobileApps}>
        <a
          className={styles.mobileApps__link}
          href={mobileApps ? mobileApps.ios_link : storeLinks.appStore}
          onClick={yandexMetrika.reachGoalButtonClickAppStoreNew}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/app-store-black.svg" alt="App Store" />
        </a>
        <a
          className={styles.mobileApps__link}
          href={mobileApps ? mobileApps.android_link : storeLinks.googlePlay}
          onClick={yandexMetrika.reachGoalButtonClickGooglePlayNew}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/google-play-black.svg" alt="Google Play" />
        </a>
      </div>
    </header>
  )
}
