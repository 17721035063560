import { format, isSameDay, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';

export function formatEventPeriod(startAt: string, endAt: string) {
  const startDate = parseISO(startAt);
  const endDate = parseISO(endAt);

  const startDay = format(startDate, 'd MMMM', { locale: ru });
  const startTime = format(startDate, 'HH:mm');
  const endDay = format(endDate, 'd MMMM', { locale: ru });
  const endTime = format(endDate, 'HH:mm');

  if (isSameDay(startDate, endDate)) {
    return `${startDay} с ${startTime} по ${endTime}`;
  } else {
    return `с ${startDay} ${startTime} по ${endDay} ${endTime}`;
  }
}