import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Title, Container, Input, Button, FormError, Icon, FixedBottom, Link } from '@/components';
import { getAreaInfoById } from '@/store/slices/stagesSlice/stagesSlice';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { checkisCoupon } from '@/helpers/checkIsCoupon';
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles';
import EmailInput from '@/components/ui/EmailInput';
import styles from './CarNumberPage.module.scss';
import { Subscription } from '@/components/pages/SubscriptionsPage/SubscriptionsPage';
import axios from 'axios';
import getConfig from 'next/config';
import { IAreaSubscription, SubscriptionInfoResponseData } from '@/store/slices/stagesSlice/interfaces';
import { useRouter } from 'next/router';
import { supportPhone, supportPhoneLink } from '@/constants';

interface ICarNumberPageProps {
  value: string;
  email?: string;
  onChange: (value: string) => void;
  onChangeEmail?: (value: string) => void;
  onSubmit: () => void;
  areaId: string;
  error?: string;
  emailError?: string;
  loading?: boolean;
  isDiscountPage?: boolean;
  isSubscriptionPage?: boolean;
  subscriptionId?: string;
}

const { publicRuntimeConfig } = getConfig()

export default function CarNumberPage({
  value,
  email,
  onChange,
  onChangeEmail,
  onSubmit,
  error,
  emailError,
  loading = false,
  areaId,
  isDiscountPage = false,
  isSubscriptionPage = false,
  subscriptionId,
}: ICarNumberPageProps) {
  useAreaStyles(areaId);
  const reduxState = useSelector<RootState, RootState>(state => state);
  const router = useRouter()
  const { data: areaInfo } = getAreaInfoById(reduxState, areaId);
  const [currentSubscription, setCurrentSubscription] = useState<IAreaSubscription | null>(null)
  const numberRef = useRef(null);
  const isSubmitDisabled = value.length <= 2 || currentSubscription?.available_count === 0;
  const isOnlyCarNumber = isSubscriptionPage || areaInfo?.ticket_type === 'only_car' || !areaInfo;

  useEffect(() => {
    if(subscriptionId) {
      (async () => {
        try {
          const { data } = await axios.get<{ data: SubscriptionInfoResponseData }>(`${publicRuntimeConfig.browserApiUrl}/web/subscriptions/${subscriptionId}`);
          setCurrentSubscription(data.data.subscription)
        } catch (error: any) {
          if(error?.response?.status === 404) {
            router.push(`/${areaId}/subscriptions`)
          }
        }
      })()
    }
  }, [subscriptionId])

  useEffect(() => {
    let field = (numberRef.current as unknown) as HTMLInputElement;
    field.focus();
  }, [error]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\s/g, '');
    const isCoupon = checkisCoupon(value);
    const valueAsArray = value.split('');

    if (isCoupon && valueAsArray.length > 3 && valueAsArray[3] !== ' ') {
      valueAsArray.splice(3, 0, ' ');
    }

    if (isCoupon && valueAsArray.length > 7 && valueAsArray[7] !== ' ') {
      valueAsArray.splice(7, 0, ' ');
    }

    value = valueAsArray.join('');
    onChange(value);
  };

  const onEmailChange = useCallback((value: string) => {
    onChangeEmail?.(value);
  }, []);

  const _onSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!isSubmitDisabled) {
      await onSubmit();
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _onSubmit();
    }
  };

  return (
    <section className={styles.section}>
      <Container
        className={cn(styles.container, {
          [styles['container--discount-page']]: isDiscountPage,
        })}
      >
        <div>
          {subscriptionId && currentSubscription &&
            <div className={styles.subscription}>
              <Subscription
                {...currentSubscription}
                areaId={areaId}
                cardColor={areaInfo?.customising?.cards_color}
              />
            </div>
          }

          <Title className={cn(styles.title, { [styles.title_isDiscountPage]: isDiscountPage })}>
            {isOnlyCarNumber
              ? 'Укажите гос. номер авто'
              : 'Укажите гос. номер авто или номер талона'}
          </Title>

          <form onSubmit={_onSubmit}>
            <label className={styles.input}>
              <FormError
                error={error}
                className={cn(styles.input__error, {
                  [styles.input__error_isDiscountPage]: isDiscountPage,
                })}
              >
                <Input
                  value={value}
                  onChange={onInputChange}
                  ref={numberRef}
                  placeholder={isOnlyCarNumber ? 'A012BC123' : 'А012ВС12 или 312 755 7900'}
                  onKeyPress={handleKeyPress}
                  danger={!!error}
                  isDiscountPage
                  className={styles.inputNumber}
                  disabled={currentSubscription?.available_count === 0}
                />
              </FormError>
            </label>
          </form>
          <div className={styles.message}>
            <Icon name='attention-icon' />
            <div>
              <Title
                level={4}
                className={cn(styles.message__title, {
                  [styles.message__title_isDiscountPage]: isDiscountPage,
                })}
              >
                Гос. номер должен быть чистым
              </Title>
              <p
                className={cn(styles.message__text, {
                  [styles.message__text_isDiscountPage]: isDiscountPage,
                })}
              >
                Шлагбаум открывается автоматически при распознавании гос. номера
              </p>
            </div>
          </div>

          {onChangeEmail && email !== undefined ? (
            <label
              className={cn(styles.input, styles['input--email'], styles['input--discount-page'])}
            >
              <Title
                level={4}
                className={cn(styles.message__title, {
                  [styles.message__title_isDiscountPage]: isDiscountPage,
                })}
              >
                Укажите ваш email
              </Title>
              <EmailInput
                onChange={onEmailChange}
                onKeyPress={handleKeyPress}
                value={email}
                error={emailError}
                className={styles.emailInput__wrapper}
                classNameInput={styles.emailInput}
              />
            </label>
          ) : (
            <></>
          )}
        </div>

        <FixedBottom isHide isDiscountPage={isDiscountPage}>
          <footer className={styles.footer}>
            <Button
              type='submit'
              withLoader
              loading={loading}
              onClick={_onSubmit}
              className={styles.button}
              disabled={isSubmitDisabled}
              background={areaInfo?.customising.button_back_color}
            >
              Продолжить
            </Button>
            {isDiscountPage ? (
              <div className={styles.footer__message}>
                Номер технической поддержки сервиса HippoParking:{' '}
                <a className={styles.footer__message_isDesktop} href={supportPhoneLink}>
                  {supportPhone}
                </a>
                <a className={styles.footer__message_isTablet} href="#">
                  {supportPhone}
                </a>
              </div>
            ) : (
              <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
                Обратиться в поддержку
              </Button>
            )}

            {subscriptionId && currentSubscription?.available_count === 0 &&
              <div className={styles.subscription__error}>
                <p>
                  В данный момент все места по абонементу уже заняты. Вы можете приобрести другой абонемент.
                </p>
                <Link href={`/${areaId}/subscriptions`}>Абонементы</Link>
              </div>
            }
          </footer>
        </FixedBottom>
      </Container>
    </section>
  );
}
