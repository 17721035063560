import { useSelector } from 'react-redux'
import { getAreaInfoById, getMobileApps } from '@/store/slices/stagesSlice/stagesSlice'
import { RootState } from '@/store'
import { AreaPageHeader } from '@/components/pages/AreaPageModern/components/AreaPageHeader/AreaPageHeader'
import { AreaPageMain } from '@/components/pages/AreaPageModern/components/AreaPageMain/AreaPageMain'
import { AreaPageFooter } from '@/components/pages/AreaPageModern/components/AreaPageFooter/AreaPageFooter'
import styles from './AreaPageModern.module.scss'

export default function AreaPageModern({ id }: { id: string }) {
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: area } = getAreaInfoById(reduxState, id)
  const { data: mobileApps } = getMobileApps(reduxState)

  return (
    <div className={styles.page}>
      <AreaPageHeader mobileApps={mobileApps} />
      <AreaPageMain area={area} />
      <AreaPageFooter content={area?.footer_content} />
    </div>
  )
}
