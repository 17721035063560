import * as React from 'react'

const SVG = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32822_17279)">
      <path
        d="M9.38672 16C9.51562 16.1992 9.74219 16.332 10 16.332C10.2578 16.332 10.4844 16.1992 10.6172 16L14.9219 9.35156C15.5 8.45703 15.8359 7.47266 15.8359 6.33203C15.832 3.03516 13.2422 0.5 10 0.5C6.75781 0.5 4.16797 3.03516 4.16797 6.33203C4.16797 7.47266 4.50391 8.45703 5.08203 9.35156L9.38672 16ZM10 3C11.8398 3 13.332 4.49219 13.332 6.33203C13.332 8.17188 11.8398 9.66406 10 9.66406C8.16016 9.66406 6.66797 8.17188 6.66797 6.33203C6.66797 4.49219 8.16016 3 10 3ZM14.2734 13.1367L10 18.832L5.72656 13.1367C4.26563 13.9023 3.33203 15.0469 3.33203 16.332C3.33203 18.6328 6.31641 20.5 10 20.5C13.6836 20.5 16.668 18.6328 16.668 16.332C16.668 15.0469 15.7344 13.9023 14.2734 13.1367Z"
        fill="#3879F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_32822_17279">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default SVG
