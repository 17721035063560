import {
  changeUserEmail,
  getAreaInfoById,
  getPaymentInfoById,
  getPaymentState,
  resetPaymentInfo,
  startPayment,
  getFinesInfoById,
  resetFinesInfo,
} from '@/store/slices/stagesSlice/stagesSlice'
import { Button, Container, Text, Time, Title } from '@/components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import Rates from './Rates'
import { useRouter } from 'next/router'
import { KeyboardEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import CarNumberLabel from './CarNumberLabel'
import styles from './PaymentPage.module.scss'
import getLinkHref from '@/helpers/getLinkHref'
import { getFormattedCarNumber } from '@/helpers/getFormattedCarNumber'
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles'
import EmailInput from '@/components/ui/EmailInput'
import NoPaymentRequired from './NoPaymentRequired'
import { supportPhoneLink } from '@/constants';

export default function PaymentPage({ stageId, areaId }: { stageId: string; areaId: string }) {
  useAreaStyles(areaId)
  const reduxState = useSelector<RootState, RootState>(state => state)
  const dispatch = useDispatch()
  const router = useRouter()
  const [carNumber, setCarNumber] = useState('')

  const { data: areaInfo } = getAreaInfoById(reduxState, areaId)
  const { data } = getPaymentInfoById(reduxState, stageId)
  const { loading } = getPaymentState(reduxState)
  const { data: finesInfo } = getFinesInfoById(reduxState, stageId)
  const rates = data?.invoicing ?? []
  const time = data?.range ?? 0
  const paidUntil = data?.paid_until ?? null
  const totalPrice = data?.amount ?? '0'
  const isNoPaymentRequired = data && +data?.amount === 0 && finesInfo?.totalAmount === 0
  const areaInn = data?.area?.inn ?? ''
  const areaLegalName = data?.area?.legal_name ?? ''
  const isRenterDiscountAvailable = data?.area.is_renter_discount_available

  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const onInputChange = useCallback((value: string) => {
    setError('')
    setValue(value)
  }, [])

  const handleStartPayment = async () => {
    const email = value.trim()

    if (email && !email.match(/.+[@].+/gm)) {
      setError('Укажите корректный email')
      return
    }

    await dispatch(changeUserEmail({ email }))

    await dispatch(
      startPayment({
        id: stageId,
        onSuccess: ({ data }) => router.push(data.url),
      }),
    )
  }

  const handleQrCodeButtonClick = async () => {
    const applyReceiptDiscountHref = getLinkHref({
      type: 'apply-receipt-discount',
      data: { areaId, stageId },
    })
    router.push(applyReceiptDiscountHref)
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleStartPayment()
    }
  }

  useLayoutEffect(() => {
    const number = getFormattedCarNumber()
    setCarNumber(number)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetPaymentInfo())
      dispatch(resetFinesInfo())
    }
  }, [])

  if (isNoPaymentRequired) {
    return <NoPaymentRequired area={areaInfo} paidUntil={paidUntil} />
  }

  const isShowFinesInfo = finesInfo && finesInfo?.fines?.length > 0
  const isScanQrCodeButtonAvailable = isRenterDiscountAvailable && +data.amount > 0

  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <div>
          <Container>
            <div className={styles.header}>
              <Title className={styles.price}>
                {Math.round((finesInfo?.totalAmount || 0) + Number(totalPrice))} ₽
              </Title>
              {rates.length > 0 && (
                <Text className={styles.time}>
                  <Time timeInSeconds={time} />
                </Text>
              )}
              {carNumber ? <CarNumberLabel number={carNumber} /> : <></>}
            </div>
          </Container>
          <Container>
            <div className={styles.content}>{rates.length > 0 && <Rates rates={rates} />}</div>

            {isShowFinesInfo && (
              <div className={styles.finesWrapper}>
                <p>Штраф</p>
                <ul>
                  {finesInfo.fines.map(fine => (
                    <li key={fine.id}>{fine.amount} руб.</li>
                  ))}
                </ul>
                <ul>
                  {finesInfo.fines.map(fine => (
                    <li key={fine.id}>{fine.amount} ₽</li>
                  ))}
                </ul>
              </div>
            )}
            <div className={styles.finesWrapper}>
              <p>Итого: </p>
              <p>{Math.round((finesInfo?.totalAmount || 0) + Number(totalPrice))} ₽</p>
            </div>
          </Container>

          <Container className={styles.container}>
            <Title className={styles.content__title} level={4} size={24}>
              Укажите ваш email
            </Title>

            <Text level={2} className={styles.content__paragraph}>
              Ваш email необходим, чтобы мы могли прислать чек об успешной оплате.
            </Text>
            <EmailInput
              onChange={onInputChange}
              onKeyPress={handleKeyPress}
              value={value}
              error={error}
            />
          </Container>

          <Container>
            <div className={styles.requisites}>
              <span>{areaLegalName}</span>
              <span>ИНН {areaInn}</span>
            </div>
          </Container>
        </div>

        <Container className={styles.buttons}>
          {isScanQrCodeButtonAvailable && (
            <Button
              withLoader
              onClick={handleQrCodeButtonClick}
              className={styles.button}
              background={areaInfo?.customising.button_back_color}
            >
              Сканировать QR-код по чеку
            </Button>
          )}
          <Button
            withLoader
            onClick={handleStartPayment}
            className={styles.button}
            loading={loading}
            background={areaInfo?.customising.button_back_color}
          >
            К оплате
          </Button>
          <Button component="a" href={supportPhoneLink} className={styles.button} isTransparent>
            Обратиться в поддержку
          </Button>
        </Container>
      </div>
    </section>
  )
}
