import { Container, ActiveLink, Icon } from '@/components'
import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './Header.module.scss'
import cn from 'classnames'
import { RootState } from '@/store'
import { getAreaInfoById, getPaymentState } from '@/store/slices/stagesSlice/stagesSlice'
import { useSelector } from 'react-redux'

export type HeaderLayout = 'logo' | 'back' | 'discount-page' | 'payment-page'

export default function Header({
  layout = 'logo',
}: {
  layout?: HeaderLayout
}) {
  const { query } = useRouter()
  const isDiscountPage = layout === 'discount-page'
  const isPaymentPage = layout === 'payment-page'
  const reduxState = useSelector<RootState, RootState>(state => state)
  const { data: areaInfo } = getAreaInfoById(reduxState, query.areaId as string)
  const { data: paymentInfo, loading } = getPaymentState(reduxState)

  return (
    <header className={cn(styles.header)}>
      <Container
        className={cn(styles.container, { [styles['container--discount']]: isDiscountPage })}
      >
        <HeaderLogo
          withoutLink={isDiscountPage || isPaymentPage}
          logoSquare={
            (isPaymentPage
              ? paymentInfo?.area?.customising?.logo_square
              : areaInfo?.customising.logo_square) as string
          }
          loading={loading}
          isPaymentPage={isPaymentPage}
        />
        {layout === 'back' && (
          <HeaderBack buttonBackColor={areaInfo?.customising.button_back_color as string} />
        )}
      </Container>
    </header>
  )
}

function HeaderLogo({
  withoutLink,
  logoSquare,
  loading = false,
  isPaymentPage = false,
}: {
  withoutLink: boolean
  logoSquare: string | null
  loading?: boolean
  isPaymentPage?: boolean
}) {
  const {
    query: { areaId },
  } = useRouter()
  const href = getLinkHref({ type: 'area', data: { id: areaId } })
  const [loadingLogo, setLoadingLogo] = useState(isPaymentPage ? loading : false)

  useEffect(() => {
    isPaymentPage && setLoadingLogo(loading)
  }, [loading])

  return withoutLink ? (
    <div className={cn(styles.logo, styles.logo__withoutLink)}>
      {!loadingLogo && <img src={logoSquare ?? '/images/logo.png'} alt="logo" />}
    </div>
  ) : (
    <ActiveLink className={styles.logo} href={href} activeClass={styles.logo__Active}>
      <a>{!loadingLogo && <img src={logoSquare ?? '/images/logo.png'} alt="logo" />}</a>
    </ActiveLink>
  )
}

function HeaderBack({ buttonBackColor }: { buttonBackColor: string | null }) {
  const router = useRouter()
  const onBackClick = useCallback(() => {
    router.back()
  }, [])

  return (
    <button
      style={{ color: buttonBackColor as string }}
      type="button"
      className={styles.back}
      onClick={onBackClick}
    >
      <Icon name="chevron-left" />
      Назад
    </button>
  )
}
