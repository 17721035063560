import { Header } from '@/components'
import { LayoutProps } from './interfaces'
import styles from './Layout.module.scss'

export default function Layout({ headerLayout, children }: LayoutProps) {
  const withHeader = headerLayout !== 'empty'

  return (
    <div className={styles.wrap}>
      <noscript>
        <div><img src="https://mc.yandex.ru/watch/99998859" style={{position: "absolute", left: "-9999px"}} alt="" /></div>
      </noscript>
      {withHeader && <Header layout={headerLayout} />}
      <main className={styles.content}>{children}</main>
    </div>
  )
}
