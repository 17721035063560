import { Title } from '@/components'

import styles from '../PaymentReceiptPage.module.scss'
import { supportPhoneLink } from '@/constants';

export default function ReceiptGeneration({ timer }: { timer: string }) {
  return (
    <div>
      <div className={styles.titleWithTimer}>
        <Title level={2} className={styles.subtitle}>
          Идет процесс формирования чека
        </Title>
        <span className={styles.timer}>{timer}</span>
      </div>
      <p className={styles.description}>
        Когда чек будет готов, мы перенаправим вас на страницу, где вы сможете посмотреть, а также
        распечатать чек при необходимости.
      </p>
      <p className={styles.description}>
        Если по истечении времени таймера, вы не получили чек, то попробуйте перезагрузить страницу
        или{' '}
        <a href={supportPhoneLink} className={styles.link}>
          обратитесь в поддержку
        </a>
        .
      </p>
    </div>
  )
}
