import { getAreaInfoById, getPartnerPromotions } from '@/store/slices/stagesSlice/stagesSlice';
import { Container, PaymentCard, SubscriptionCard, EventCard } from '@/components';
import styles from './AreaPage.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import cn from 'classnames';
import { useEffect, useLayoutEffect, useRef } from 'react';
import getConfig from 'next/config';
import axios from 'axios';
import { useAreaStyles } from '@/helpers/hooks/useAreaStyles';
import { AreasSlug } from '@/constants';
import PaymentInAppCard from '@/components/Card/PaymentInAppCard';
import PaymentInKPlazaAppCard from '@/components/Card/PaymentInKPlazaAppCard';
import PartnerPromotions from '@/components/PartnerPromotions';
import { yandexMetrika } from '@/helpers/yandexMetrika';

const { publicRuntimeConfig } = getConfig()

interface MobileResponse {
  android_current_version: string
  android_link: string
  android_required_version: string
  ios_current_version: string
  ios_link: string
  ios_required_version: string
}

export default function AreaPage({ id }: { id: string }) {
  useAreaStyles(id)
  const reduxState = useSelector<RootState, RootState>(state => state);
  const { data } = getAreaInfoById(reduxState, id);
  const { data: partnerPromotions } = getPartnerPromotions(reduxState)
  const playMarketRef = useRef<HTMLAnchorElement>(null)
  const iosRef = useRef<HTMLAnchorElement>(null)
  const isKashirskayaPlaza = id === AreasSlug.KashirskayaPlaza
  const isKapitoliyBelyaevo = id === AreasSlug.KapitoliyBelyaevo
  const isParkovkaZil = id === AreasSlug.ParkovkaZil

  useLayoutEffect(() => {
    const sheet = data?.customising.style_sheet;
    if (sheet) {
      document.body.style.setProperty('--font-main', `'${sheet}', sans-serif`);
      document.body.style.setProperty('--font-secondary', `'${sheet}', sans-serif`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const {data} = await axios.get<{ data: MobileResponse }>(`${publicRuntimeConfig.browserApiUrl}/versions/mobile`);
        if(playMarketRef?.current) playMarketRef.current.href = data.data.android_link
        if(iosRef?.current) iosRef.current.href = data.data.ios_link
      }
       catch (_err){}
    })()
  }, [])

  const isShowPartnerPromotions =
    partnerPromotions &&
    partnerPromotions?.length > 0 &&
    !isKashirskayaPlaza &&
    !isKapitoliyBelyaevo &&
    !isParkovkaZil

  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div>
          <section className={styles.preview__wrapper}>
            <div className={styles.preview}>
              {data?.preview && (
                <img src={data.preview} alt={data?.name} className={styles.preview__image} />
              )}
            </div>
            {data?.customising.logo_circle ? (
              <div className={cn(styles.preview__logoCircle)}>
                <img
                  className={styles.preview__customisingLogo}
                  src={data?.customising.logo_circle}
                  alt="logo"
                />
              </div>
            ) : (
              <img className={styles.preview__logo} src={'/images/preview-logo.svg'} alt="logo" />
            )}
          </section>

          <h1 className={styles.title}>{data?.name}</h1>

          {!isKashirskayaPlaza && (
            <section className={styles.mobileApps}>
              <a ref={playMarketRef} target="_blank" rel="noreferrer noopener" onClick={yandexMetrika.reachGoalButtonClickGooglePlay}>
                <img
                  className={styles.mobileApp}
                  src="https://app.hippoparking.ru/public_files/area/1691425/87ee9841-f628-46f8-8f19-ae83ea8c86be.png"
                  alt="Google Play"
                />
              </a>
              <a ref={iosRef} target="_blank" rel="noreferrer noopener" onClick={yandexMetrika.reachGoalButtonClickAppStore}>
                <img
                  className={styles.mobileApp}
                  src="https://app.hippoparking.ru/public_files/area/1691424/Frame-39273.png"
                  alt="App Store"
                />
              </a>
            </section>
          )}

          <div className={styles.cards}>
            {isShowPartnerPromotions && <PartnerPromotions partnerPromotions={partnerPromotions} />}
            {data?.show_rates && <PaymentCard areaId={id} />}
            {isKashirskayaPlaza && (
              <>
                <PaymentInKPlazaAppCard />
                <PaymentInAppCard iosRef={iosRef} playMarketRef={playMarketRef} />
              </>
            )}
            {data?.show_subscriptions && <SubscriptionCard areaId={id} />}
            {data?.show_events && <EventCard areaId={id} />}
          </div>
        </div>
        {data?.footer_content && (
          <Container>
            <footer className={styles.footer}>
              <div dangerouslySetInnerHTML={{ __html: data.footer_content }} />
            </footer>
          </Container>
        )}
      </section>
    </div>
  )
}
