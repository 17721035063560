import Link from 'next/link'
import { Icon, Title } from '@/components'
import { supportPhoneLink } from '@/constants';
import styles from './SubscriptionFieldsPage.module.scss'

export default function SubscriptionPageHeader({
  areaHref,
  areaName,
}: {
  areaHref: string
  areaName: string
}) {
  return (
    <header className={styles.header}>
      <div className={styles.header__top}>
        <Link href={areaHref}>
          <div className={styles.header__logo}>
            <img className={styles.logoImage} src="/images/logo.png" alt="Логотип HippoParking" />
            <Title tag="span" className={styles.logoTitle}>
              HippoParking
            </Title>
          </div>
        </Link>
        <a href={supportPhoneLink} className={styles.header__phone}>
          <Icon name="fill-phone-icon" />
        </a>
      </div>

      <Title className={styles.header__title}>
        <span>Абонемент</span>
        {areaName}
      </Title>
    </header>
  )
}
